import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import DifferenceIcon from "@mui/icons-material/Difference";
import "./index.scss";

interface ServicesProps {
  products?: any;
  productsSettings?: any;
  dark?: boolean;
  button?: boolean;
  brand?: any;
  limit?: any;
  light?: any;
  sector?: any;
  links?: any;
}

const Comparisons: React.FC<ServicesProps> = ({
  products,
  productsSettings,
  brand = false,
  button,
  limit = 18,
  light,
  sector,
  links = false,
}) => {
  const groupedByBrand = products.reduce((acc: any, product: any) => {
    if (!acc[product.brand]) {
      acc[product.brand] = [];
    }
    acc[product.brand].push(product);
    return acc;
  }, {});

  function shuffle(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const combinationsByBrandArray = [];

  for (const brand in groupedByBrand) {
    const brandProducts = groupedByBrand[brand];
    const combinations = [];
    for (let i = 0; i < brandProducts.length; i++) {
      for (let j = i + 1; j < brandProducts.length; j++) {
        combinations.push([brandProducts[i], brandProducts[j]]);
      }
    }
    // Shuffle the combinations
    const shuffledCombinations = shuffle(combinations);
    combinationsByBrandArray.push({
      brand: brand,
      combinations: shuffledCombinations,
    });
  }

  const formatSlug = (title: any) => {
    return title.toLowerCase().split("-").slice(0, -1).join("-");
  };

  if (links) {
    return (
      <>
        {combinationsByBrandArray?.map((e: any, i: any) => {
          if (brand && e?.brand !== brand) return null;
          return (
            <ul className="sitemap-compare" key={i}>
              {e?.combinations
                ?.slice(0, limit)
                ?.reduce((acc: any[], cur: any, idx: number) => {
                  if (idx % 3 === 0) {
                    acc.push([]);
                  }
                  acc[acc.length - 1].push(cur);
                  return acc;
                }, [])
                ?.map((group: any, h: number) => {
                  return (
                    <li key={h}>
                      {group.map((u: any, i: number) => {
                        const image = u[0]?.images
                          ? getImage(u[0]?.images[0]?.src)
                          : false;
                        const image2 = u[1]?.images
                          ? getImage(u[1]?.images[0]?.src)
                          : false;
                        return (
                          <Link
                            to={`/${formatSlug(u[0]?.slug)}-vs-${formatSlug(
                              u[1]?.slug
                            )}`}
                            key={i}
                          >
                            {u[0]?.title} VS {u[1]?.title}
                            <br />
                          </Link>
                        );
                      })}
                    </li>
                  );
                })}
            </ul>
          );
        })}
      </>
    );
  } else {
    return (
      <section className={light ? "comparisons-all" : "comparisons-all light"}>
        <div className="container">
          <h3>{productsSettings?.comparePageSubtitleTitle}</h3>
          <h2>{productsSettings?.comparePageTitle}</h2>
          <p className="compare-desc">{productsSettings?.comparePageContent}</p>
          {combinationsByBrandArray?.map((e: any, i: any) => {
            if (brand && e?.brand !== brand) return null;
            return (
              <div key={i}>
                {sector !== "pro" && <h3>{e?.brand}</h3>}
                <div className="comparisons-list">
                  {e?.combinations
                    ?.slice(0, limit)
                    ?.reduce((acc: any[], cur: any, idx: number) => {
                      if (idx % 3 === 0) {
                        acc.push([]);
                      }
                      acc[acc.length - 1].push(cur);
                      return acc;
                    }, [])
                    ?.map((group: any, h: number) => {
                      return (
                        <div className="comparisons-item" key={h}>
                          {group.map((u: any, i: number) => {
                            const image = u[0]?.images
                              ? getImage(u[0]?.images[0]?.src)
                              : false;
                            const image2 = u[1]?.images
                              ? getImage(u[1]?.images[0]?.src)
                              : false;
                            return (
                              <div className="comparison-subitem" key={i}>
                                <div className="comparisons-item-head">
                                  <div
                                    className={`comapre-wrap-prod fil${u[0]?.filter}`}
                                  >
                                    <Link to={`/${u[0]?.slug}`}>
                                      <GatsbyImage image={image} alt="" />
                                      <h5>{u[0]?.title}</h5>
                                    </Link>
                                  </div>
                                  <span>{productsSettings?.comparePageVs}</span>
                                  <div
                                    className={`comapre-wrap-prod fil${u[1]?.filter}`}
                                  >
                                    <Link to={`/${u[1]?.slug}`}>
                                      <GatsbyImage image={image2} alt="" />
                                      <h5>{u[1]?.title}</h5>
                                    </Link>
                                  </div>
                                </div>
                                <div className="comparisons-link">
                                  <Link
                                    to={`/${formatSlug(
                                      u[0]?.slug
                                    )}-vs-${formatSlug(u[1]?.slug)}`}
                                  >
                                    {productsSettings?.comparePageViewLink}
                                  </Link>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
                {button && (
                  <div className="compare-button">
                    <Link to={productsSettings.comparePageLink}>
                      <button type="button" className="orangeButton">
                        {productsSettings.comparePageButtonView}
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </section>
    );
  }
};

export default Comparisons;
