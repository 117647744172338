import * as React from "react";
import { Link } from "gatsby";
import Comparisons from "../Comparisons/";
import "./index.scss";

interface SitemapPropsType {
  products?: any;
  productsSettings?: any;
  alldata?: any;
  settings?: any;
}

const Sitemap: React.FC<SitemapPropsType> = ({
  products,
  alldata,
  settings,
  productsSettings,
}) => {
  return (
    <section className="sitemap">
      <div className="container">
        <h3>{settings?.group1}</h3>
        <ul>
          {alldata
            ?.filter((e: any) => e?.type === "page")
            .map((e: any, i: any) => {
              return (
                <li key={i}>
                  <Link to={`/${e?.slug !== "/" ? e?.slug : ""}`} key={i}>
                    {e?.title}
                  </Link>
                </li>
              );
            })}
        </ul>
        <h3>{settings?.group2}</h3>
        <ul>
          {alldata
            ?.filter((e: any) => e?.type === "product")
            .map((e: any, i: any) => {
              return (
                <li key={i}>
                  <Link to={`/${e?.slug !== "/" ? e?.slug : ""}`} key={i}>
                    {e?.title}
                  </Link>
                </li>
              );
            })}
        </ul>
        <h3>{settings?.group3}</h3>
        <ul>
          {alldata
            ?.filter((e: any) => e?.type === "article")
            .map((e: any, i: any) => {
              return (
                <li key={i}>
                  <Link to={`/${e?.slug !== "/" ? e?.slug : ""}`} key={i}>
                    {e?.title}
                  </Link>
                </li>
              );
            })}
        </ul>
        <h3>{settings?.group4}</h3>
        <Comparisons
          products={products}
          limit={1000}
          productsSettings={productsSettings}
          links={true}
        />
      </div>
    </section>
  );
};

export default Sitemap;
